class Tooltip {
  constructor(toggles) {
    this.toggleButtons = Array.from(toggles);

    this.toggleButtons.forEach((toggle) => {
      const tipHeading = toggle.getAttribute('data-tooltip-heading');
      const tipContent = toggle.getAttribute('data-tooltip-content');
      const tipElement = toggle.nextElementSibling;

      // Add event listeners.
      toggle.addEventListener('click', () => this.clickHandler(tipElement, this.getMessage(tipHeading, tipContent)));
      toggle.addEventListener('keydown', (e) => this.keyPressHandler(e, tipElement));
      document.addEventListener('click', (e) => {
        if (e.target !== toggle && e.target !== toggle.children[0]) {
          tipElement.innerHTML = '';
        }
      })
    })
  }

  clickHandler(tip, message) {
    if (this.isTipVisible(tip)) {
      tip.innerHTML = '';
    } else {
      tip.innerHTML = message;
    }
  }

  keyPressHandler(event, tip) {
    if (event.key === 'Escape') {
      tip.innerHTML = '';
    }
  }

  isTipVisible(tip) {
    return tip.children.length > 0;
  }

  getMessage(heading, content) {
    // Message to display to the user.
    return `
    <div class="tooltip__tip">
        <div class="tooltip__tip-label text--uppercase text--bold">${heading}</div>
        <div class="tooltip__tip-text">
          ${content}
        </div>
    </div>`;
  }
}

window.addEventListener('load', () => {
  // Find all tooltip toggles.
  const toggles = document.querySelectorAll('[data-tooltip-toggle]');

  // Init toggles
  if (toggles) {
    new Tooltip(toggles);
  }
});
